<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col md="8" sm="6">
        <div>
          <h1>SCHEDULE FOLLOW UP</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'FollowUp' })"
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-autocomplete
          label="Search / Select Customer"
          :items="customer_list"
          item-text="first_name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          label="Follow Up Type:"
          v-model="scheduleFU_info.followup_type"
          :items="scheduleFU_info.no_bid_reason"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <date-time :date="true"> </date-time>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-textarea
          label="Comments:"
          auto-grow
          outlined
          rows="3"
          row-height="40"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTime from "../../components/DateTime/DateTime";
import CustomerServices from "../../service/CustomerServices";

export default {
  components: { DateTime },
  name: "ScheduleFU.vue",
  data() {
    return {
      customer_list: [],
      scheduleFU_info: {
        followup_type: "",
      },
    };
  },
  created() {
    CustomerServices.List(this.$route.params.id)
      .then((res) => {
        this.customer_list = res.results;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped></style>
