import RequestManager from "./RequestManager";
export default new (class CustomerServices {
  componentURL = "Customer";
  customerSourceURL = "CustomerSource";

  customerEthnicityURL = "EthnicityType";
  customerMaritalStatusURL = "MaritalStatusTypes";
  customerProfessionURL = "Profession";
  customerIncomeURL = "IncomeRange";
  customerEducationURL = "EducationType";
  customerMilitaryURL = "MilitaryTypes";

  CustomerSearch = "CustomerSearch";

  List() {
    return RequestManager.get(this.componentURL);
  }
  Create(args) {
    return RequestManager.post(this.componentURL, args);
  }
  Detail(id) {
    return RequestManager.get(`${this.componentURL}/${id}`);
  }
  Source() {
    return RequestManager.get(this.customerSourceURL);
  }
  Ethnicity() {
    return RequestManager.get(this.customerEthnicityURL);
  }
  MaritalStatus() {
    return RequestManager.get(this.customerMaritalStatusURL);
  }
  Profession() {
    return RequestManager.get(this.customerProfessionURL);
  }
  Income() {
    return RequestManager.get(this.customerIncomeURL);
  }
  Education() {
    return RequestManager.get(this.customerEducationURL);
  }
  Military() {
    return RequestManager.get(this.customerMilitaryURL);
  }
})();
